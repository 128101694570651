<template>
  <div class="homePageDiv" v-loading="divLoading">
    <div class="backUpDiv" @click="onClickHomepage">
      <i class="el-icon-back"/>
    </div>
    <div class="contentDiv">
      <div class="imageTitle">
        {{ imageData.title }}
      </div>
      <div class="imageContent">
        <div v-if="hiddenContent">
          <div v-if="imageData.contents != null && imageData.contents[0].type === 'img'" class="descriptionImage">
            <img :src="imageData.contents[0].src" alt=""/>
          </div>
          <div v-if="imageData.contents != null && imageData.contents[0].type === 'text'" class="descriptionText">
            {{ imageData.contents[0].content }}
          </div>
          <el-button type="text" style="font-size: 16px; font-weight: bold; margin-left: 18px;"
                     @click="hiddenContent=false">
            MORE<i class="el-icon-arrow-right el-icon--right" style="font-weight: bold"/>
          </el-button>
        </div>
        <div v-else>
          <div v-for="(description, index) in imageData.contents" :key="'images_' + index">
            <div v-if="description.type === 'img'" class="descriptionImage">
              <img :src="description.src" alt=""/>
            </div>
            <div v-if="description.type === 'text'" class="descriptionText">
              {{ description.content }}
            </div>
          </div>
        </div>
      </div>
      <div class="recommendDiv">
        <div class="title">
          Recommendations
        </div>
        <div v-for="(image, index) in this.recommendList" :key="'news_' + index" class="recommendImageContent"
             @click="onClickItem(image)">
          <div class="imageTitleDiv">
            <span class="titleSpan">{{ image.title }}</span>
            <span class="publishedSpan">{{ image.publisher }}</span>
          </div>
          <div class="imageCoverImage" :style="'background-image: url('+image.pictureUrl+')'"></div>
        </div>
      </div>
    </div>
    <footerUI/>
    <BackTopComp/>
  </div>
</template>

<script>
import footerUI from '../footerUI';
import BackTopComp from "@/view/BackTopComp";
import ImageService from "@/service/ImageService";

export default {
  components: {
    footerUI, BackTopComp
  },
  data() {
    return {
      divLoading: false,
      imageData: {},
      recommendList: [],
      hiddenContent: true,
    };
  },
  computed: {},
  created() {
    let articleId = this.$route.params.articleId;
    let categoryId = this.$route.params.categoryId;
    let category = ImageService.getCategoryList().find(item => item.id === categoryId)
    ImageService.getImagesList(category.path).then((response) => {
      this.divLoading = false;
      let imageDataList = response.data;
      imageDataList.forEach((imageData) => {
        if (imageData.articleId === articleId) {
          imageData.contents = imageData.contents.reverse();
          this.imageData = imageData;
        } else {
          this.recommendList.push(imageData);
        }
      })
    })
  },
  mounted() {
    console.log("-homepage-------mounted");
    window.addEventListener("resize", this.onResize);
    // 页面刷新时的处理逻辑
    window.addEventListener("beforeunload", this.handleBeforeUnload);
    window.addEventListener("unload", this.handleUnload);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    // 清理事件监听
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
    window.removeEventListener("unload", this.handleUnload);
    console.log("清理事件--------destroyed");
  },
  beforeRouteEnter(to, from, next) {
    // 路由返回到当前页面时的处理逻辑
    console.log("000000000000000-----------beforeRouteEnter");
    next(() => {
      // 通过 `vm` 访问组件实例
    });
  },

  beforeRouteLeave(to, from, next) {
    // 路由离开当前页面时的处理逻辑
    console.log("000000000000000-----------beforeRouteLeave");
    next();
  },
  methods: {
    onResize() {

    },
    handleBeforeUnload() {

    },
    handleUnload() {

    },
    onClickHomepage() {
      window.location.href = "/category/" + this.$route.params.categoryId;
    },
    onClickItem(image) {
      window.location.href = "/imageDetails/" + this.$route.params.categoryId + "/" + image.articleId;
    }
  }
}
</script>

<style lang="scss" scoped>
.homePageDiv {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fff;

  .backUpDiv {
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 18px;
    left: 18px;
    border-radius: 5px;
    color: white;

    i {
      font-size: 20px;
      font-weight: bold;
      display: block;
    }
  }

  .contentDiv {
    flex-grow: 1;
    text-align: left;

    .imageTitle {
      color: #222;
      font-size: 20px;
      font-weight: bold;
      margin: 18px 18px 10px;
    }

    .imageContent {
      .descriptionImage {
        min-height: 100px;
        margin: 10px 18px;

        img {
          width: 100%;
          display: block;
        }
      }

      .descriptionText {
        line-height: 1.5;
        color: #000;
        font-size: 14px;
        margin: 10px 18px 0;
      }
    }

    .recommendDiv {

      .title {
        color: #333;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        margin: 16px 18px 2px;
        display: flex;
        align-items: center;
      }

      .recommendImageContent {
        padding: 18px;
        display: flex;
        align-items: flex-start;
        border-bottom: 2px solid #f4f4f4;

        .imageCoverImage {
          height: 100px;
          width: 140px;
          background-size: 100% 100%;
          border-radius: 5px;
        }

        .imageTitleDiv {
          color: #000;
          text-align: left;
          height: 100px;
          width: calc(100% - 155px);
          padding-right: 15px;
          display: flex;
          flex-wrap: wrap;
          align-content: space-between;

          .titleSpan {
            font-size: 16px;
            font-weight: 600;
            height: 48px;
            line-height: 24px;
            margin-top: 0;
            overflow: hidden;
            display: block;
            width: 100%;
          }

          .publishedSpan {
            color: #999;
            font-size: 14px;
            font-weight: 600;
            height: 20px;
            line-height: 20px;
            margin-bottom: 0;
            margin-top: 10px;
            width: 100%;
          }
        }
      }
    }
  }

  .advertisement_p {
    margin: 0;
    font-size: 12px;
    color: white;
  }
}
</style>
