import Vue from "vue";
import Router from "vue-router";
import userAgreement from "@/view/userAgreement";
import privacyPolicy from "@/view/privacyPolicy";
import HomePage from "@/view/template1/HomePage";
import DetailsPage from "@/view/template1/DetailsPage";

Vue.use(Router);
let routes = [
    {
        path: "/",
        name: "homePage",
        component: HomePage
    },
    {
        path: "/category/:categoryId",
        name: "homePage",
        component: HomePage
    },
    {
        path: "/imageDetails/:categoryId/:articleId",
        name: "detailsPage",
        component: DetailsPage
    },
    {
        path: "/privacyPolicy",
        name: "privacyPolicy",
        component: privacyPolicy
    },
    {
        path: "/userAgreement",
        name: "userAgreement",
        component: userAgreement
    },
];
const router = new Router({
    //  mode: 'history',
    mode: process.env.IS_ELECTRON ? "hash" : "history",
    base: process.env.BASE_URL,
    routes
});

// 使用全局前置守卫来重置滚动位置
router.beforeEach((to, from, next) => {
    if (to.path !== from.path) {
        window.scrollTo(0, 0); // 滚动到页面的顶部
    }
    console.log("from.matched：", from);
    console.log("to.matched：", to);
    next(); // 必须调用next()来resolve这个钩子
});

export default router;
