<template>
  <el-backtop :bottom="bottom" :right="right" style="background-color: #007eff;">
    <svg class="icon" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <path
          d="M538.048 885.504c-5.504 50.368-75.904 45.888-78.592 0L459.392 338.56 217.344 572.16c-37.952 32.64-83.136-19.52-54.912-53.888C269.632 414.336 462.08 228.352 466.432 224a42.56 42.56 0 0 1 64.64 0c35.264 34.176 297.344 286.848 305.28 295.936 26.56 33.024-16.384 81.92-53.12 54.4-9.408-8.064-245.12-235.776-245.12-235.776M161.792 51.2h670.72v76.16H161.728V51.2z"
          fill="#ffffff"></path>
    </svg>
  </el-backtop>
</template>

<script>
export default {
  name: "BackTopComp",
  props: {
    bottom: {
      type: Number,
      default: 100
    },
    right: {
      type: Number,
      default: 15
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
