<template>
  <div class="homePageDiv" v-loading="divLoading">
    <div class="contentDiv">
      <div class="headerDiv">
        <div class="logoDiv" @click="onClickHomepage">
          <img class="logoImage" src="/images/news-logo1.png?ran=2" alt=""/>
        </div>
        <div class="headerSvgDiv">
          <el-button type="text" style="padding: 0; border: 0;" @click="categoryDrawer = !categoryDrawer">
            <svg class="icon" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="32" height="32">
              <path
                  d="M384 928H192a96 96 0 0 1-96-96V640a96 96 0 0 1 96-96h192a96 96 0 0 1 96 96v192a96 96 0 0 1-96 96zM192 608a32 32 0 0 0-32 32v192a32 32 0 0 0 32 32h192a32 32 0 0 0 32-32V640a32 32 0 0 0-32-32H192zM784 928H640a96 96 0 0 1-96-96V640a96 96 0 0 1 96-96h192a96 96 0 0 1 96 96v144a32 32 0 0 1-64 0V640a32 32 0 0 0-32-32H640a32 32 0 0 0-32 32v192a32 32 0 0 0 32 32h144a32 32 0 0 1 0 64zM384 480H192a96 96 0 0 1-96-96V192a96 96 0 0 1 96-96h192a96 96 0 0 1 96 96v192a96 96 0 0 1-96 96zM192 160a32 32 0 0 0-32 32v192a32 32 0 0 0 32 32h192a32 32 0 0 0 32-32V192a32 32 0 0 0-32-32H192zM832 480H640a96 96 0 0 1-96-96V192a96 96 0 0 1 96-96h192a96 96 0 0 1 96 96v192a96 96 0 0 1-96 96zM640 160a32 32 0 0 0-32 32v192a32 32 0 0 0 32 32h192a32 32 0 0 0 32-32V192a32 32 0 0 0-32-32H640z"
                  fill="#000000"></path>
            </svg>
          </el-button>
        </div>
      </div>
      <div class="imageDataDiv">
        <div v-for="(image, index) in imageDataList" :key="'images_' + index"
             :class="index === 0 ? 'rowImage1' : 'rowImage2'"
             @click="onClickItem(image)">
          <img class="coverImage" :src="image.pictureUrl" alt="">
          <div class="imageDescription">
            <p class="title">{{ image.title }}</p>
            <p class="publishAt">{{ image.publishAt }}</p>
          </div>
        </div>
      </div>
    </div>
    <el-drawer :with-header="false" size="60%" style="padding-top: 60px; max-width: 750px; margin: auto;"
               direction="rtl" :visible.sync="categoryDrawer" :show-close="false" :append-to-body="true">
      <div v-for="(category, index) in categoryList" :key="'news_category_' + index"
           @click="onSearchByTag(category.id)"
           style="padding: 10px; margin: 10px; box-shadow: rgba(31, 31, 31, 0.4) 0 0.1875rem 0.5rem 0; display: flex; align-items: center;">
        <img :src="category.svg" alt="icon"
             :style="'width: ' + (category.width ? category.width : '24px') + '; height: ' + (category.height ? category.height : '24px') + ';'">
        <div
            style="display: flex; align-items: center; text-align: left; color: black; font-size: 14px; margin-left: 10px;">
          {{ category.name }}
        </div>
      </div>
    </el-drawer>
    <footerUI/>
    <BackTopComp/>
  </div>
</template>

<script>
import footerUI from '../footerUI';
import BackTopComp from "@/view/BackTopComp";
import ImageService from "@/service/ImageService";

export default {
  components: {
    footerUI, BackTopComp
  },
  data() {
    return {
      divLoading: false,
      categoryDrawer: false,
      categoryList: ImageService.getCategoryList(),
      imageDataList: []
    };
  },
  computed: {},
  created() {
    let category = null;
    if (this.$route.path.includes("/category/")) {
      let categoryId = this.$route.params.categoryId;
      category = this.categoryList.find(item => item.id === categoryId)
    }
    if (category == null) {
      category = this.categoryList[0];
    }
    ImageService.getImagesList(category.path).then((response) => {
      this.divLoading = false;
      this.imageDataList = response.data;
      this.imageDataList = this.imageDataList.concat(this.imageDataList).concat(this.imageDataList).concat(this.imageDataList);
    })
  },
  mounted() {
    console.log("-homepage-------mounted");
    window.addEventListener("resize", this.onResize);
    // 页面刷新时的处理逻辑
    window.addEventListener("beforeunload", this.handleBeforeUnload);
    window.addEventListener("unload", this.handleUnload);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    // 清理事件监听
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
    window.removeEventListener("unload", this.handleUnload);
    console.log("清理事件--------destroyed");
  },
  beforeRouteEnter(to, from, next) {
    // 路由返回到当前页面时的处理逻辑
    console.log("000000000000000-----------beforeRouteEnter");
    next(() => {
      // 通过 `vm` 访问组件实例
    });
  },

  beforeRouteLeave(to, from, next) {
    // 路由离开当前页面时的处理逻辑
    console.log("000000000000000-----------beforeRouteLeave");
    next();
  },
  methods: {
    onResize() {

    },
    handleBeforeUnload() {

    },
    handleUnload() {

    },
    onClickHomepage() {
      window.location.href = "/";
    },
    onSearchByTag(id) {
      window.location.href = "/category/" + id;
    },
    onClickItem(image) {
      let categoryId = this.categoryList[0].id;
      if (this.$route.path.includes("/category/")) {
        categoryId = this.$route.params.categoryId;
      }
      window.location.href = "/imageDetails/" + categoryId + "/" + image.articleId;
    }
  }
}
</script>

<style lang="scss" scoped>
.homePageDiv {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fff;

  .contentDiv {
    flex-grow: 1;

    .headerDiv {
      border-bottom: 1px solid #DCDFE6;
      align-items: center;
      display: flex;
      position: sticky;
      top: 0;
      z-index: 9999;
      background-color: white;
      height: 59px;
      padding: 0 10px;
      justify-content: space-between;

      .logoDiv .logoImage {
        display: block;
        height: 40px;
      }

      .headerSvgDiv {
        width: 32px;
        height: 32px;
      }
    }

    .imageDataDiv {
      padding: 5px;
      display: flex;
      flex-wrap: wrap;

      .rowImage1 {
        padding: 10px;
        width: calc(100% - 20px);
        position: relative;

        .coverImage {
          width: 100%;
          display: block;
          height: 360px;
          object-fit: cover;
          max-height: 50vw;
        }

        .imageDescription {
          position: absolute;
          width: calc(100% - 40px);
          bottom: 10px;
          padding: 10px;
          text-align: left;
          background-color: rgba(0, 0, 0, 0.6);
          color: white;

          .title {
            font-size: 16px;
            margin: 0;
          }

          .publishAt {
            font-size: 12px;
            margin: 10px 0 0;
          }
        }
      }

      .rowImage2 {
        margin: 10px;
        width: calc(50% - 20px);
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, .12);

        .coverImage {
          width: 100%;
          display: block;
          height: 250px;
          object-fit: cover;
          max-height: 40vw;
        }

        .imageDescription {
          text-align: left;
          padding: 15px 10px;

          .title {
            font-size: 16px;
            margin: 0;
            font-weight: 700;
            color: #333;
            height: 50px;
            line-height: 25px;
            overflow: hidden;
          }

          .publishAt {
            font-size: 12px;
            margin: 10px 0 0;
            color: #999;
          }
        }
      }
    }
  }

  .advertisement_p {
    margin: 0;
    font-size: 12px;
    color: white;
  }
}
</style>
