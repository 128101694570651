export default {
    categories: [
        {"id": "1", "name": "Animal", "path": "/Animal/Animal.json", "svg": "animal.svg"},
        {"id": "2", "name": "Beauty", "path": "/Beauty/Beauty.json", "svg": "beauty.svg"},
        {"id": "3", "name": "Car", "path": "/Car/Car.json", "svg": "car.svg"},
        {"id": "4", "name": "Delicacy", "path": "/Delicacy/Delicacy.json", "svg": "delicacy.svg"},
        {"id": "5", "name": "Information", "path": "/Information/Information.json", "svg": "information.svg"},
        {"id": "6", "name": "Life", "path": "/Life/Life.json", "svg": "life.svg"},
        {"id": "7", "name": "Sports", "path": "/Sports/Sports.json", "svg": "sports.svg"},
        {"id": "8", "name": "Technology", "path": "/Technology/Technology.json", "svg": "technology.svg"},
        {"id": "9", "name": "Travel", "path": "/Travel/Travel.json", "svg": "travel.svg"},
    ]
}
