import ImagesCategory from "@/model/ImagesCategory";
import axios from "axios";

export default {

    getCategoryList() {
        let categories = JSON.parse(JSON.stringify(ImagesCategory.categories));
        categories.forEach((category) => {
            category.svg = require('@/assets/' + category.svg);
        })
        return categories;
    },

    getImagesList(categoryPath) {
        return axios.get("https://fusionsky-news.oss-ap-southeast-1.aliyuncs.com" + categoryPath);
    },
}
